import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ChatIcon from '@material-ui/icons/Chat';

import { OptionsContext } from "../../pages/TicketsAdvanced";

const useStyles = makeStyles(theme => ({
    header: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'inherit'
    },
    buttonAction: {
        padding: '6px 5px 8px !important'
    }
}));

const BottomTicketNavigationAction = (props) => {
    const classes = useStyles();
    const {option, setOption} = useContext(OptionsContext);

    return (
        <BottomNavigation
            value={option}
            onChange={(event, newValue) => {
                setOption(newValue);
            }}
            showLabels
            className={classes.header}
        >
            <BottomNavigationAction label="Ticket" icon={<ChatIcon />} className={classes.buttonAction}/>
            <BottomNavigationAction label="Atendimentos" icon={<QuestionAnswerIcon />} className={classes.buttonAction}/>
        </BottomNavigation>
    );
};

export default BottomTicketNavigationAction;
